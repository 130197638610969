import React from 'react';
import Container from './ui/Container';
import SectionTitle from './ui/SectionTitle';
import { Briefcase, GraduationCap, Calendar } from 'lucide-react';

const TimelineItem = ({ year, title, subtitle, items = [], icon: Icon }) => (
  <div className="relative pl-12 sm:pl-44 py-6 group">
    <div className="flex flex-col sm:flex-row items-start mb-1 group-hover:text-blue-600 transition-colors">
      <div className="absolute left-0 sm:left-32 flex items-center justify-center">
        <div className="w-10 h-10 rounded-full border-2 border-blue-600 flex items-center justify-center bg-white group-hover:bg-blue-600 transition-colors">
          <Icon className="w-5 h-5 text-blue-600 group-hover:text-white transition-colors" />
        </div>
      </div>
      <time className="sm:absolute left-0 text-blue-600 font-semibold flex items-center mb-4 sm:mb-0">
        <Calendar className="w-4 h-4 mr-2" />
        {year}
      </time>
      <div className="flex-grow">
        <h3 className="text-xl font-bold text-gray-800 group-hover:text-blue-600 transition-colors">{title}</h3>
        <p className="text-gray-600">{subtitle}</p>
        {items.length > 0 && (
          <ul className="mt-4 space-y-2">
            {items.map((item, index) => (
              <li key={index} className="flex items-center text-gray-600">
                <span className="w-2 h-2 rounded-full bg-blue-600 mr-2"></span>
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
);

const Experience = () => {
  return (
    <section id="experience" className="py-20 bg-gray-50">
      <Container>
        <SectionTitle>Education and Experience</SectionTitle>
        
        <div className="max-w-4xl mx-auto">
          <div className="relative border-l-2 border-blue-200">
            <TimelineItem
              year="2021 - 2024"
              title="Web developer"
              subtitle="Primo Round, Venezia"
              items={[
                "Developed WordPress websites based on mockups",
                "Customized and created themes and plugins",
                "Optimization of website performance, with a focus on SEO and overall efficiency"
              ]}
              icon={Briefcase}
            />
            
            <TimelineItem
              year="2019 - 2020"
              title="Web developer"
              subtitle="Mcircle, Mestre"
              items={[
                "Developed WordPress websites based on mockups",
                "Managed and maintained existing platforms"
              ]}
              icon={Briefcase}
            />
            
            <TimelineItem
              year="2015 - 2018"
              title="Bachelor’s Degree in Multimedia Sciences and Technologies"
              subtitle="University of Udine"
              icon={GraduationCap}
            />
            
            <TimelineItem
              year="2015 - 2018"
              title="High School Diploma in Languages"
              subtitle="Marco Belli Language High School, Portogruaro"
              icon={GraduationCap}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Experience;