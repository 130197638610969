import React from 'react';
import Container from './ui/Container';
import SectionTitle from './ui/SectionTitle';
import Card from './ui/Card';
import { Mail, Linkedin, Github } from 'lucide-react';

const SocialLink = ({ icon: Icon, href, label }) => (
  <a
    href={href}
    className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon className="w-5 h-5" />
    <span>{label}</span>
  </a>
);

const Contact = () => {
  return (
    <section id="contact" className="py-20 bg-gray-50">
      <Container>
        <SectionTitle>Contacts</SectionTitle>
        
        <Card className="max-w-2xl mx-auto text-center p-8">
          <h3 className="text-2xl font-semibold mb-6 text-gray-800">
          Need More Information?
          </h3>
          <p className="text-gray-600 mb-8">
          If you have any questions, want to see my full portfolio, or simply discuss a project, feel free to reach out. I'm happy to assist!
          </p>
          
          <div className="flex flex-col sm:flex-row gap-6 justify-center items-center mb-8">
            <SocialLink
              icon={Mail}
              href="mailto:cominsara96@gmail.com"
              label="Email"
            />
            <SocialLink
              icon={Linkedin}
              href="https://it.linkedin.com/in/sara-comin-a674a2177"
              label="LinkedIn"
            />
           {/* <SocialLink
              icon={Github}
              href="https://github.com"
              label="GitHub"
            /> */}
          </div>
          
          <a 
            href="mailto:cominsara96@gmail.com" 
            className="inline-flex items-center justify-center bg-blue-600 text-white px-8 py-4 rounded-full hover:bg-blue-700 transition-colors w-full sm:w-auto"
            id="mailto-button"
          >
            <Mail className="mr-2" />
            Contact Me
          </a>
        </Card>
      </Container>
    </section>
  );
};

export default Contact;