import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-scroll';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { href: "about", label: "About me" },
    { href: "experience", label: "Experience" },
    { href: "skills", label: "Skills" },
    { href: "contact", label: "Contacts" }
  ];

  return (
    <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm z-50 border-b border-gray-100">
      <nav className="container mx-auto px-6 py-4">
        {/* Mobile menu button */}
        <div className="md:hidden flex justify-end">
          <button
            onClick={toggleMenu}
            className="text-gray-600 hover:text-blue-600 transition-colors"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Desktop menu */}
        <ul className="hidden md:flex gap-8 justify-center text-gray-600">
          {menuItems.map((item) => (
            <li key={item.href}>
              <Link
                to={item.href}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="hover:text-blue-600 transition-colors cursor-pointer"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>

        {/* Mobile menu */}
        {isMenuOpen && (
          <ul className="md:hidden absolute top-full left-0 right-0 bg-white border-b border-gray-100 py-4 px-6 space-y-4">
            {menuItems.map((item) => (
              <li key={item.href}>
                <Link
                  to={item.href}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="block hover:text-blue-600 transition-colors cursor-pointer"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </header>
  );
};

export default Header;