import React from 'react';
import Container from './ui/Container';
import SectionTitle from './ui/SectionTitle';
import Card from './ui/Card';
import { User, Lightbulb, Target } from 'lucide-react';

const AboutCard = ({ icon: Icon, title, description }) => (
  <Card className="text-center p-8">
    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 text-blue-600 mb-6">
      <Icon size={24} />
    </div>
    <h3 className="text-xl font-semibold mb-4 text-gray-800">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </Card>
);

const About = () => {
  const features = [
    {
      icon: User,
      title: "WordPress Development",
      description: "I am a developer with experience in creating optimized and customized WordPress websites. I transform mockups into functional solutions, ensuring high-quality design and code."
    },
    {
      icon: Lightbulb,
      title: "Technical and Creative Skills",
      description: "With a solid programming background, I blend technical expertise with strong knowledge in marketing and communication to deliver complete and versatile web solutions."
    },
    {
      icon: Target,
      title: "Strategy and Performance",
      description: "My approach combines technical precision with strategic vision, ensuring each website is not only functional but also effective in meeting business and marketing needs."
    }
  ];

  return (
    <section id="about" className="py-20 bg-white">
      <Container>
        <SectionTitle>About me</SectionTitle>
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {features.map((feature, index) => (
            <AboutCard key={index} {...feature} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default About;