import React from 'react';
import Container from './ui/Container';
import SectionTitle from './ui/SectionTitle';
import Card from './ui/Card';
import { Code, Globe, LineChart, Star } from 'lucide-react';

const SkillCard = ({ icon: Icon, title, skills, className }) => (
  <Card className={`relative overflow-hidden group ${className}`}>
    <div className="absolute top-0 right-0 -mt-4 -mr-4 w-24 h-24 bg-blue-100 rounded-full blur-2xl opacity-0 group-hover:opacity-70 transition-opacity"></div>
    <Icon className="w-10 h-10 text-blue-600 mb-6 relative z-10" />
    <h3 className="text-xl font-semibold mb-4 text-gray-800 relative z-10">{title}</h3>
    <ul className="space-y-3 relative z-10">
      {skills.map((skill, index) => (
        <li key={index} className="flex items-center text-gray-600">
          <Star className="w-4 h-4 text-blue-600 mr-2" />
          {skill}
        </li>
      ))}
    </ul>
  </Card>
);

const Skills = () => {
  const skillSets = [
    {
      icon: Code,
      title: "Programming Languages",
      skills: ["HTML & CSS", "JavaScript", "PHP", "React", "Responsive Design"]
    },
    {
      icon: Globe,
      title: "WordPress",
      skills: ["Custom Themes", "Plugin Development", "Elementor", "WooCommerce", "Gutenberg"]
    },
    {
      icon: LineChart,
      title: "Marketing & Analytics",
      skills: ["Google Analytics", "Tag Manager", "SEO", "CRM Integration", "Performance Tracking"]
    }
  ];

  return (
    <section id="skills" className="py-20 bg-white">
      <Container>
        <SectionTitle>Skills</SectionTitle>
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {skillSets.map((skillSet, index) => (
            <SkillCard key={index} {...skillSet} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Skills;