import React from 'react';

const Card = ({ children, className = '' }) => {
  return (
    <div className={`bg-white/50 backdrop-blur-sm rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 ${className}`}>
      {children}
    </div>
  );
};

export default Card;