import React from 'react';
import Container from './ui/Container';
import { ArrowRight } from 'lucide-react';

const Hero = () => {
  return (
    <section id="hero" className="pt-32 pb-16 min-h-screen flex items-center bg-gradient-to-b from-blue-50 via-white to-white">
      <Container>
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <div className="text-center lg:text-left space-y-8">
            <div className="space-y-4">
              <span className="px-4 py-2 rounded-full bg-blue-100 text-blue-700 text-sm font-medium inline-block">
                Hybrid Web Developer
              </span>
              <h1 className="text-4xl md:text-6xl font-bold text-gray-900">
                Hi, i'm <span className="text-blue-600">Sara</span>
              </h1>
            </div>
            <p className="text-xl text-gray-600 leading-relaxed">
            I am a hybrid web developer with experience in creating optimized and customized WordPress sites. I combine technical programming skills with a solid understanding of marketing and communication.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
              <a 
                href="#contact"
                className="inline-flex items-center justify-center bg-blue-600 text-white px-8 py-4 rounded-full hover:bg-blue-700 transition-colors group"
                id="mailto-to"
              >
                Contact me
                <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
              </a>
             {/*<a 
                href="#portfolio"
                className="inline-flex items-center justify-center px-8 py-4 rounded-xl border-2 border-gray-200 hover:border-blue-600 transition-colors text-gray-700 hover:text-blue-600"
              >
                Vedi i miei lavori
              </a>*/}
            </div>
          </div>
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-500 rounded-full blur-3xl opacity-20 animate-pulse"></div>
            <img 
              src={`${process.env.PUBLIC_URL}/transparent-background-chick.webp`} 
              alt="Workspace"
              className="relative w-1/2 object-cover transform hover:scale-[1.02] transition-transform duration-300 m-auto"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;